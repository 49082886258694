/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import MainFeatures from '~/components/MainFeatures';
import PropertyAddress from '~/components/PropertyAddress';
import PropertyCarousel from '~/components/PropertyCarousel';
import PropertyPriceParse from '~/components/PropertyPriceParse';
import usePropertyInView from '~/utils/usePropertyInView';
import HideListing from '~/components/HideListing';
import styles from './PropertyCard.module.scss';

const PropertyCard = ({
    offsetHeaderHeight,
    property,
    ofiTime,
    isFromSearch,
    hasScrolled,
    collectionId,
    isFeed,
    sr,
    carouselImageHeight,
    isHomePage,
    showCardActions,
    isInfoBox,
    isPropertyPage,
    searchOptions,
    searchUrl,
    showHideListing,
    propertySearchVariables,
}) => {
    const { id, features, priceView, isUnderOffer, status, slugUrl, rentDateAvailable } = property;
    let formatRentDateAvailable;
    const card = useRef(null);
    const isPropertyInView = usePropertyInView({
        offsetHeaderHeight,
        hasScrolled,
        property,
        card: card.current,
    });

    if (rentDateAvailable) {
        formatRentDateAvailable = dayjs(rentDateAvailable);
    }

    const currentDate = dayjs();

    // const propertyStatus = (status || 'current').toUpperCase();

    return (
        <div className="z-0 h-full w-full" ref={card}>
            <div
                className={`${styles.PropertyCard} relative h-full w-full overflow-hidden bg-white ${
                    !isFeed && 'rounded-md'
                }`}
                key={id}
            >
                <div
                    style={{ transform: `scale(1.085, 1)` }}
                    className="relative w-full cursor-pointer overflow-hidden"
                >
                    <PropertyCarousel
                        collectionId={collectionId}
                        property={property}
                        ofiTime={ofiTime}
                        height={carouselImageHeight}
                        isFromSearch={isFromSearch}
                        searchOptions={searchOptions || null}
                        searchUrl={searchUrl}
                        sr={sr}
                        isHomePage={isHomePage}
                        isPropertyInView={isPropertyInView}
                        showActions={showCardActions}
                        isInfoBox={isInfoBox}
                        ratio="75%"
                    />
                </div>
                <div
                    className={`${
                        isPropertyPage ? `px-4 pb-2 pt-4 ${styles.isPropertyPage}` : 'p-2'
                    } relative px-4 py-4 sm:p-3 md:px-6`}
                >
                    <div className={`w-full ${isPropertyPage ? 'pr-0' : 'pr-2'}`}>
                        <span
                            className={`text-left ${
                                isPropertyPage && `mb-1 text-base text-gray-800 ${styles.textSizeSpecialCase}`
                            } flex w-full items-center font-medium`}
                        >
                            <div className={`flex-1 ${isPropertyPage ? 'break-word' : 'text-overflow'}`}>
                                <PropertyPriceParse
                                    className="text-left"
                                    property={property}
                                    rentBondClass="text-xs text-gray-700"
                                    isPropertyPage={isPropertyPage}
                                />
                            </div>
                        </span>

                        <div
                            className={`font-normal ${
                                isPropertyPage ? `text-base ${styles.textSizeSpecialCase}` : 'text-sm'
                            } mb-0.5 block text-gray-700 `}
                        >
                            <PropertyAddress
                                property={property}
                                newLine
                                query={{
                                    isFromSearch,
                                    sr,
                                    searchOptions: searchOptions || null,
                                    searchUrl,
                                }}
                                isPropertyPage={isPropertyPage}
                            />
                        </div>
                        {/* rental date available */}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {formatRentDateAvailable ? (
                            formatRentDateAvailable <= currentDate ? (
                                <div className="mb-2 text-xs font-light">Available Now</div>
                            ) : (
                                <div className="mb-2 text-xs font-light">
                                    Available {dayjs(formatRentDateAvailable).format('DD MMM YYYY')}
                                </div>
                            )
                        ) : null}
                        <div className="mb-2 mt-2 text-left">
                            <MainFeatures
                                features={features}
                                displayText={!isPropertyPage}
                                classNames={{
                                    feature: `rounded-full ${
                                        isPropertyPage
                                            ? 'font-medium mr-4 text-gray-700 text-sm'
                                            : `font-semibold mr-3 text-gray-600 ${styles.propertyFeature} text-sm`
                                    }`,
                                }}
                                duo={false}
                            />
                        </div>
                        {showHideListing ? (
                            <div className="mb-2 text-left">
                                <HideListing
                                    hideStatus="unhide"
                                    propertyId={id}
                                    propertySearchVariables={propertySearchVariables}
                                />
                            </div>
                        ) : null}
                    </div>
                    {/* {isPropertyPage && (
                        <div className="enquire-link">
                            <Link href={`/property/${slugUrl}`}>
                                <a target="_blank" rel="noopener noreferrer">
                                    Enquire
                                </a>
                            </Link>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

PropertyCard.propTypes = {
    collectionId: PropTypes.string,
    isFeed: PropTypes.bool,
    ofiTime: PropTypes.bool,
    isInfoBox: PropTypes.bool,
    property: PropTypes.shape({
        id: PropTypes.string,
        slugUrl: PropTypes.string,
        features: PropTypes.object,
        priceView: PropTypes.string,
        isUnderOffer: PropTypes.bool,
        status: PropTypes.string,
        agents: PropTypes.array,
        agency: PropTypes.object,
    }),
    isFromSearch: PropTypes.bool,
    carouselImageHeight: PropTypes.string,
    sr: PropTypes.string,
    hasScrolled: PropTypes.bool,
    isHomePage: PropTypes.bool,
    showCardActions: PropTypes.bool,
    showHideListing: PropTypes.bool,
    offsetHeaderHeight: PropTypes.number,
    isPropertyPage: PropTypes.bool,
    searchOptions: PropTypes.string,
    searchUrl: PropTypes.string,
};

PropertyCard.defaultProps = {
    collectionId: '',
    isFeed: false,
    ofiTime: false,
    isInfoBox: false,
    property: {},
    isFromSearch: false,
    carouselImageHeight: '16rem',
    sr: '',
    hasScrolled: false,
    isHomePage: false,
    showCardActions: true,
    showHideListing: false,
    offsetHeaderHeight: 0,
    isPropertyPage: false,
    searchOptions: '',
    searchUrl: '',
};
export default PropertyCard;
