import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { EnvironmentOutlined } from '@ant-design/icons';
import { buildStateAddress, buildStreetAddress } from '~/utils/property';
import clsx from 'clsx';
import styles from './PropertyAddress.module.scss';

const PropertyAddress = ({ property, newLine, icon, classNames, query, isPropertyPage }) => {
    const { isShowAddress, slugUrl, slugUrlPrefix } = property || {};

    const parseStreet = buildStreetAddress(property);
    const statePost = buildStateAddress(property);

    const cleanUrlParams = (params) => {
        return Object.keys(params).reduce((acc, key) => {
            const item = params[key];

            if (!(item === null || item === undefined || item === '' || (Array.isArray(item) && item.length === 0))) {
                acc[key] = item;
            }
            return acc;
        }, {});
    };

    return useMemo(
        () => (
            <Link
                href={{
                    pathname: `/${slugUrlPrefix}/[slug]`,
                    query: cleanUrlParams({
                        slug: slugUrl,
                        ...query,
                    }),
                }}
            >
                <span
                    className={`${styles.PropertyAddress} w-full cursor-pointer capitalize ${
                        isPropertyPage ? classNames.propertyPageMain : classNames.main
                    } `}
                >
                    {icon && (
                        <EnvironmentOutlined className="mr-3 cursor-pointer text-lg text-gray-700 hover:text-blue-500" />
                    )}
                    <span
                        className={`${clsx(
                            'transition',
                            isPropertyPage ? classNames.propertyPageStreet : classNames.street
                        )} ${isPropertyPage ? 'mb-1 inline-block font-semibold text-gray-600' : ''}`}
                    >
                        {/* <span
                        id="property-address"
                        className={`font-semibold text-gray-600 transition ${
                            isPropertyPage ? classNames.propertyPageStreet : classNames.street
                        }`}
                    > */}
                        {parseStreet || ''}
                    </span>
                    {!isShowAddress && (
                        <span
                            className={`${clsx(
                                'normal-case transition',
                                isPropertyPage ? classNames.propertyPageStreet : classNames.street,
                                isPropertyPage ? 'mb-1 inline-block text-gray-800' : ''
                            )}`}
                        >
                            {/* <span
                            className={`normal-case transition ${
                                isPropertyPage ? 'mb-1 inline-block text-gray-800' : classNames.street
                            }`}
                        > */}
                            Address available on request{' '}
                        </span>
                    )}
                    {newLine && (parseStreet || !isShowAddress) && <br />}
                    {!newLine && (parseStreet || !isShowAddress) && ', '}
                    <span
                        className={`${clsx(
                            'transition',
                            isPropertyPage ? classNames.propertyPageSuburb : classNames.suburb,
                            isPropertyPage ? 'font-normal text-gray-800' : ''
                        )}`}
                    >
                        {/* <span
                        className={`transition ${
                            isPropertyPage ? 'font-normal text-gray-800' : 'font-normal text-gray-700'
                        }`}
                    > */}
                        {statePost || ''}
                    </span>
                </span>
            </Link>
        ),
        [property]
    );
};

PropertyAddress.propTypes = {
    classNames: PropTypes.shape({
        main: PropTypes.string,
        street: PropTypes.string,
        suburb: PropTypes.string,
        propertyPageMain: PropTypes.string,
        propertyPageStreet: PropTypes.string,
        propertyPageSuburb: PropTypes.string,
    }),
    icon: PropTypes.bool,
    isFromSearch: PropTypes.bool,
    newLine: PropTypes.bool,
    property: PropTypes.shape({
        address: PropTypes.string,
        postalCode: PropTypes.string,
        suburb: PropTypes.string,
        street: PropTypes.string,
        state: PropTypes.string,
        streetNumber: PropTypes.string,
        isShowAddress: PropTypes.bool,
        subNumber: PropTypes.string,
    }),
    query: PropTypes.shape({ isFromSearch: PropTypes.bool, slug: PropTypes.string }),
    isPropertyPage: PropTypes.bool,
};

PropertyAddress.defaultProps = {
    classNames: {
        main: 'text-overflow inline-block leading-normal',
        street: 'text-gray-800',
        suburb: 'text-gray-700 font-normal',
        propertyPageMain: 'inline-block leading-normal',
        propertyPageStreet: 'text-gray-800',
        propertyPageSuburb: 'text-gray-800 font-normal',
    },
    icon: false,
    newLine: false,
    property: {},
    query: { isFromSearch: false, slug: null },
    isPropertyPage: false,
};

export default PropertyAddress;
