import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import isEmpty from 'lodash/isEmpty';
import { trackEvent } from '~/lib/analytics';
import useWindowSize from '~/utils/useWindowSize';

const usePropertyInView = ({ card, offsetHeaderHeight, hasScrolled, property }) => {
    const { id, agency, agents } = property;
    const [isPropertyInView, setIsPropertyInView] = useState(false);
    const { height } = useWindowSize();

    const handleCardPosition = () => {
        if (!isEmpty(card) && agents && agents.length) {
            const cardBounds = card.getBoundingClientRect();
            if (cardBounds.top >= offsetHeaderHeight && cardBounds.bottom < height) {
                trackEvent('Search Result Appearance', { dimension_1: parseInt(id, 10) });
                setIsPropertyInView(true);
            } else {
                setIsPropertyInView(false);
            }
        }
    };

    const [getCardPosition] = useDebouncedCallback(handleCardPosition, 50);

    useEffect(() => {
        if (!hasScrolled) {
            getCardPosition();
        }
        window.addEventListener('scroll', getCardPosition);

        return () => {
            window.removeEventListener('scroll', getCardPosition);
        };
    }, [hasScrolled]);

    return isPropertyInView;
};

export default usePropertyInView;
