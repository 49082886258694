import { capitalize } from '~/utils/string';

export const buildStateAddress = property => {
    let stateAddress = '';

    const { state, address, postalCode, suburb } = property || {};

    if (postalCode && suburb && state) {
        stateAddress = `${capitalize(suburb)}, ${state.toUpperCase()} ${postalCode}`.trim();
    } else if (address) {
        const splitAddress = address.split(',');

        if (splitAddress.length >= 2) {
            const statePostal = `${splitAddress[splitAddress.length - 1]}`.trim();
            stateAddress = `${splitAddress[splitAddress.length - 2]}, ${statePostal}`.trim();
        }
    }

    return stateAddress;
};

export const buildStreetAddress = property => {
    let streetAddress = '';

    const { address, street, streetNumber, isShowAddress, subNumber } = property || {};

    if (!isShowAddress) {
        return '';
    }

    if (street) {
        const trimmedStreet = capitalize(
            street
                .replace('null', '')
                .replace(',', '')
                .trim()
        );

        streetAddress = `${subNumber || ''}${streetNumber && subNumber ? '/' : ''}${streetNumber ||
            ''} ${trimmedStreet}`.trim();
    } else if (address) {
        const statePost = `${buildStateAddress(property)}`.toLowerCase();

        let replacedAddress = address
            .toLowerCase()
            .replace(statePost, '')
            .toLowerCase()
            .trim();

        if (`${street}`.endsWith(',')) {
            replacedAddress = street.slice(0, -1);
        }
        streetAddress = capitalize(`${replacedAddress}`);
    }

    return streetAddress;
};

export const buildFullAddress = property => {
    let fullAddress = '';

    const { isShowAddress } = property || {};

    if (!isShowAddress) {
        fullAddress = buildStateAddress(property);
    } else {
        const statePost = buildStateAddress(property);
        const parseStreet = buildStreetAddress(property);
        fullAddress = `${parseStreet}, ${statePost}`;
    }

    return fullAddress;
};
