/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import PropertyPrice from '~/components/PropertyPrice';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

const RenderPrice = memo((props) => {
    const {
        sellingPrice,
        price,
        isShowSoldPrice,
        isShowPrice,
        priceView,
        rentPriceMonthly,
        rentPriceWeekly,
        isShowRentPriceWeekly,
        isShowRentPriceMonthly,
        rentBond,
        type,
        status,
        soldPrice,
        soldDate,
        rentBondClass,
        auction,
    } = props;

    const newStatus = status || 'CURRENT';

    switch (newStatus) {
        case 'CURRENT': {
            if (priceView) {
                const priceViewText = <span dangerouslySetInnerHTML={{ __html: priceView }} />;
                return priceViewText;
            }

            if (type === 'RENTAL') {
                const monthly =
                    rentPriceMonthly && isShowRentPriceMonthly ? (
                        <>
                            <PropertyPrice showDecimal={false} className="font-bold" price={rentPriceMonthly} />
                            &nbsp;/month
                        </>
                    ) : null;
                const weekly =
                    rentPriceWeekly && isShowRentPriceWeekly ? (
                        <span key="weekly" className="font-bold">
                            <PropertyPrice showDecimal={false} className="font-bold" price={rentPriceWeekly} />
                            &nbsp; /week
                        </span>
                    ) : null;
                const bond = rentBond ? (
                    <>
                        &nbsp;
                        <span className={rentBondClass}>| Rent Bond: ${rentBond}</span>
                    </>
                ) : null;

                const rentPrice =
                    monthly || weekly ? (
                        <>
                            {monthly}
                            &nbsp;
                            {weekly}
                        </>
                    ) : (
                        'Contact Agent'
                    );

                return (
                    <>
                        {rentPrice}
                        &nbsp;
                        {bond}
                    </>
                );
            }

            if (isShowPrice && sellingPrice) {
                return <PropertyPrice showDecimal={false} className="font-bold" price={sellingPrice} />;
            }

            const isAuction = auction ? dayjs().isSameOrBefore(dayjs(auction.auction)) : false;
            return <span className="font-bold">{isAuction ? 'Auction' : price}</span>;
        }
        case 'SOLD': {
            return isShowSoldPrice && soldPrice ? (
                <>
                    <span className="block">
                        Sold at &nbsp;
                        <PropertyPrice showDecimal={false} className="font-bold" price={soldPrice} />
                    </span>
                    {soldDate && (
                        <span className="block text-sm text-gray-400">
                            Sold on&nbsp;
                            {dayjs.utc(soldDate).format('MMMM D, YYYY')}
                        </span>
                    )}
                </>
            ) : (
                <>
                    {/* <span className="block">{priceView}</span> */}
                    <span dangerouslySetInnerHTML={{ __html: priceView }} className="block" />
                    <span className="block text-sm text-gray-400">
                        {soldDate ? (
                            <>
                                Sold on&nbsp;
                                {dayjs.utc(soldDate).format('MMMM D, YYYY')}
                            </>
                        ) : (
                            'Sold'
                        )}
                    </span>
                </>
            );
        }
        default: {
            return null;
        }
    }
});

RenderPrice.propTypes = {
    sellingPrice: PropTypes.number,
    price: PropTypes.string,
    isShowSoldPrice: PropTypes.bool,
    isShowPrice: PropTypes.bool,
    priceView: PropTypes.string,
    rentPriceMonthly: PropTypes.number,
    rentPriceWeekly: PropTypes.number,
    isShowRentPriceWeekly: PropTypes.bool,
    isShowRentPriceMonthly: PropTypes.bool,
    rentBond: PropTypes.number,
    type: PropTypes.string,
    status: PropTypes.string,
    soldPrice: PropTypes.number,
    soldDate: PropTypes.string,
    rentBondClass: PropTypes.string,
};
RenderPrice.defaultProps = {
    sellingPrice: 0,
    price: '',
    isShowPrice: false,
    isShowSoldPrice: false,
    priceView: '',
    rentPriceMonthly: 0,
    rentPriceWeekly: 0,
    isShowRentPriceWeekly: true,
    isShowRentPriceMonthly: false,
    rentBond: 0,
    type: '',
    status: '',
    soldPrice: 0,
    soldDate: '',
    rentBondClass: '',
};

const PropertyPriceParse = memo((props) => {
    const { property, rentBondClass, isPropertyPage } = props;
    return (
        <span className={`${isPropertyPage ? 'break-word' : 'overflow-hidden text-ellipsis break-all'}`}>
            <RenderPrice {...property} rentBondClass={rentBondClass} />
        </span>
    );
});

PropertyPriceParse.propTypes = {
    property: PropTypes.object,
    rentBondClass: PropTypes.string,
    isPropertyPage: PropTypes.bool,
};

PropertyPriceParse.defaultProps = {
    property: {},
    rentBondClass: 'text-xs text-gray-600',
    isPropertyPage: false,
};
export default PropertyPriceParse;
