import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, message, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { loginUser } from '../../actions';
import { addRecordToCache, removeRecordFromCache, findIndexByPropertyId, findIndexById } from '~/utils/graphql';
import GuestLoginForm from '../GuestLoginForm/GuestLoginForm';
import { ConsumerHidePropertyMutation, ConsumerUnhidePropertyMutation, ConsumerViewer } from './Operations.graphql';
import { HidePropertiesListQuery, PropertySearchQuery } from '../PropertySearchResults/Operations.graphql';
import styles from './HideListing.module.scss';

const HideListing = (props) => {
    const { isAuthenticated, hideStatus, onLoginUser, propertyId, userType, propertySearchVariables } = props;

    const [consumerLoginDecisionModal, setConsumerLoginDecisionModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { data: ConsumerViewerData } = useQuery(ConsumerViewer);
    const { viewer } = ConsumerViewerData || {};

    const [hideProperty, { loading }] = useMutation(ConsumerHidePropertyMutation, {
        update: (store, { data: { hideProperty: createHidePropertyData } }) => {
            try {
                const variables = {
                    consumerId: viewer.id,
                };

                addRecordToCache(store, HidePropertiesListQuery, variables, (data) => ({
                    ...data,
                    hidePropertiesList: [createHidePropertyData, ...data.hidePropertiesList],
                }));

                removeRecordFromCache(
                    store,
                    PropertySearchQuery,
                    propertySearchVariables,
                    (data) => data.propertySearch.edges,
                    (data) => findIndexByPropertyId(data, propertyId),
                    (data, recordList) => ({
                        ...data,
                        propertySearch: {
                            ...data.propertySearch,
                            edges: recordList,
                        },
                    })
                );
            } catch (err) {
                console.log(err);
            }
        },
    });

    const [unhideProperty] = useMutation(ConsumerUnhidePropertyMutation, {
        update: (store, { data: { unhideProperty: createUnhidePropertyData } }) => {
            try {
                const variables = {
                    consumerId: viewer.id,
                };

                removeRecordFromCache(
                    store,
                    HidePropertiesListQuery,
                    variables,
                    (data) => data.hidePropertiesList,
                    (data) => findIndexById(data, createUnhidePropertyData.id),
                    (data, recordList) => ({
                        ...data,
                        hidePropertiesList: recordList,
                    })
                );

                // addRecordToCache(store, PropertySearchQuery, propertySearchVariables, data => ({
                //     ...data,
                //     propertySearch: {
                //         ...data.propertySearch,
                //         edges: {
                //             ...data.propertySearch.edges,
                //             node: {
                //                 createUnhidePropertyData
                //             },
                //         }
                //     }

                // }));
            } catch (err) {
                console.log(err);
            }
        },
    });

    const closeConsumerLoginModal = () => {
        setConsumerLoginDecisionModal(false);
    };

    const onLoginSuccess = (loginData) => {
        const { accessToken, expiresIn, refreshToken, user, type } = loginData;
        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });
        closeConsumerLoginModal();
        setIsLoading(true);
        if (hideStatus === 'unhide') {
            hideProperty({ variables: { propertyId, consumerId: viewer.id } })
                .then(() => {
                    message.success('Successfully hide a Property ');
                    setIsLoading(false);
                })
                .finally(() => {
                    closeConsumerLoginModal();
                });
        } else {
            unhideProperty({ variables: { propertyId, consumerId: viewer.id } })
                .then(() => {
                    message.success('Successfully unhide a Property ');
                    setIsLoading(false);
                })
                .finally(() => {
                    closeConsumerLoginModal();
                });
        }
    };

    const onLoginSuccessPromise = (loginData) =>
        new Promise((resolve) => {
            onLoginSuccess(loginData);
            resolve(true);
        });

    const showDecisionModal = (bool) => {
        setConsumerLoginDecisionModal(bool);
    };

    const toggleHideButton = async () => {
        setIsLoading(true);
        if (hideStatus === 'unhide') {
            await hideProperty({ variables: { propertyId, consumerId: viewer.id } });
            message.success('Successfully hide a Property ');
        } else {
            await unhideProperty({ variables: { propertyId, consumerId: viewer.id } });
            message.success('Successfully unhide a Property ');
        }
        setIsLoading(false);
    };

    const handleHideUnhideButtonClick = () => {
        if (isAuthenticated) {
            toggleHideButton();
        } else {
            showDecisionModal(true);
        }
    };

    return (
        <div className={styles.hideListing}>
            {isAuthenticated && userType !== 'CONSUMER' && <span />}
            {!isAuthenticated && hideStatus === 'unhide' && (
                <Button
                    type="text"
                    icon={<EyeInvisibleOutlined style={{ fontSize: '1.125rem' }} />}
                    style={{ paddingLeft: '0px', paddingRight: '0px' }}
                    className="button-hover sm:text-base md:text-lg"
                    onClick={handleHideUnhideButtonClick}
                >
                    <span className="text-base font-light">Hide Listing</span>
                </Button>
            )}
            {!isAuthenticated && hideStatus === 'hide' && (
                <EyeOutlined className="text-lg text-[#3b82f6] sm:text-xl" onClick={handleHideUnhideButtonClick} />
            )}
            {isAuthenticated && userType === 'CONSUMER' && hideStatus === 'unhide' && (
                <Button
                    type="text"
                    icon={<EyeInvisibleOutlined />}
                    style={{ paddingLeft: '0px', paddingRight: '0px', color: '#718096' }}
                    className="font-light hover:text-[#3b82f6] sm:text-base md:text-lg"
                    onClick={handleHideUnhideButtonClick}
                >
                    <span className="text-base">Hide Listing</span>
                </Button>
            )}
            {isAuthenticated && userType === 'CONSUMER' && hideStatus === 'hide' && (
                <Tooltip title="Unhide Listing" placement="right">
                    <EyeOutlined className="text-lg text-[#3b82f6] sm:text-xl" onClick={handleHideUnhideButtonClick} />
                </Tooltip>
            )}
            <Modal
                wrapClassName="vertical-center-modal"
                visible={consumerLoginDecisionModal}
                onOk={() => showDecisionModal(false)}
                onCancel={() => showDecisionModal(false)}
                footer={null}
                zIndex={1060}
            >
                <GuestLoginForm onSuccess={onLoginSuccess} onSuccessAccount={onLoginSuccessPromise} />
            </Modal>
        </div>
    );
};

HideListing.propTypes = {
    isAuthenticated: PropTypes.bool,
    hideStatus: PropTypes.string,
    onLoginUser: PropTypes.func,
    handleClickChange: PropTypes.func,
    propertyId: PropTypes.string.isRequired,
    userType: PropTypes.string,
};

HideListing.defaultProps = {
    isAuthenticated: false,
    onLoginUser: () => {},
    handleClickChange: () => {},
    userType: null,
};

export default compose(
    connect(
        (state) => ({
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.type,
        }),
        (dispatch) => ({
            onLoginUser(accessToken) {
                dispatch(loginUser(accessToken));
            },
        })
    )
)(HideListing);
