export const formatToUnits = (number, precision) => {
    if (number === 0) {
        return number;
    }

    const abbrev = ['', 'k', 'm', 'b', 't'];
    const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
    const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
    const suffix = abbrev[order];

    let formatted = `${(number / 10 ** (order * 3)).toFixed(number > 999999 ? 2 : precision)}`;

    if (/\.0$/.test(formatted)) {
        formatted = formatted.substr(0, formatted.length - 2);
    }

    return formatted + suffix;
};

export const formatPrice = (price) => {
    const priceData = price || 0;

    return priceData.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

export const formatPriceWithTwoDecimalPoints = (price) => {
    const priceData = price || 0;

    return priceData.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
};

export const parsePrice = (price) => {
    const parsedPrice = String(price)
        .replace(/[$,]/g, '')
        .match(/-?\s*[\d\.]+/);
    if (parsedPrice === null) {
        return NaN;
    }
    return Number.parseFloat(parsedPrice);
};

export const roundNearestGroup = (num) => {
    if (num >= 1000) {
        return 1000;
    }

    if (num >= 100) {
        return Math.floor(num / 100) * 100;
    }

    if (num >= 10) {
        return Math.floor(num / 10) * 10;
    }

    return num;
};

export const formatCurrency = (value, minimumFractionDigits = 2, currency = 'AUD') =>
    new Intl.NumberFormat('en-us', {
        style: 'currency',
        currency,
        minimumFractionDigits,
        currencyDisplay: 'code',
    }).format(value);

export const currencyParser = (val) => {
    try {
        // for when the input gets clears
        if (typeof val === 'string' && !val.length) {
            val = '0.0';
        }

        // detecting and parsing between comma and dot
        const group = new Intl.NumberFormat('en-us').format(1111).replace(/1/g, '');
        const decimal = new Intl.NumberFormat('en-us').format(1.1).replace(/1/g, '');
        let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, '');
        //  => 1232.21

        // appending digits properly
        const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
        const needsDigitsAppended = digitsAfterDecimalCount > 2;

        if (needsDigitsAppended) {
            reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
        }

        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
        console.error(error);
    }
};

export const numberRangeArray = (start, end, mapValue) =>
    new Array(end - start).fill().map((d, i) => mapValue(i + start));
